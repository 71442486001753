$asb-lime:#9dd70c;
$asb-red:#d20700;
$asb-matt: #000;
$grid-float-breakpoint :1255px;

$asb-clear: rgba(138, 138, 138, 0.12);
/**
*Bootsrap overrides
*
**/

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius:            0;
//** Large `.form-control` border radius
$input-border-radius-large:      0;
//** Small `.form-control` border radius
$input-border-radius-small:      0;
//** Border color for inputs on focus
$input-border-focus:            $asb-lime;


//** Background for the dropdown menu.
$dropdown-bg:                    #000;

//** Dropdown link text color.
$dropdown-link-color:            #FFF;
//** Hover color for dropdown links.
// $dropdown-link-hover-color:      darken($gray-dark, 5%);
//** Hover background for dropdown links.
$dropdown-link-hover-bg:         lighten($asb-lime,10%);

//** Active dropdown menu item text color.
$dropdown-link-active-color:     #000;
//** Active dropdown menu item background color.

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color:   lighten($asb-lime,10%);

//** Text color for headers within dropdown menus.
$dropdown-header-color:         lighten($asb-lime,10%);

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color:           #000;


$pagination-color:                     $asb-lime;
$pagination-bg:                        $asb-matt;
$pagination-border:                    lighten($asb-lime,10%);

$pagination-hover-color:               lighten($asb-lime,10%);
$pagination-hover-border:              #ddd;

$pagination-active-color:              $asb-matt;
$pagination-active-bg:                 $asb-lime;;
$pagination-active-border:             lighten($asb-lime,10%);

$pagination-disabled-bg:               lighten($asb-lime,10%);
$pagination-disabled-border:           #ddd;


