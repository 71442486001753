
.load-overlay
{
    background: rgba(152, 158, 147, 0.52);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    display: none;
    &.active{
       display: block;
    }
}
 

.loading-tumbler{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -60px 0 0 -60px;
    animation:animationFrames 4s linear infinite;
    img{
      width:100%;
    }
}

@keyframes animationFrames{
  0% {
    transform:  rotate(0deg) ;
  }
  10% {
    transform:  rotate(75deg) ;
  }
  12% {
    transform:  rotate(60deg) ;
  }
  20% {
    transform:  rotate(160deg) ;
  }
  35% {
    transform:  rotate(270deg) ;
  }
  50% {
    transform:  rotate(180deg) ;
  }

  65% {
    transform:  rotate(160deg) ;
  }

  75% {
    transform:  rotate(60deg) ;
  }

  80% {
    transform:  rotate(60deg) ;
  }

  100% {
    transform:  rotate(0deg) ;
  }
}