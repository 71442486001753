.nav>li.active>a {
    padding: 10px 15px;
}


  .active-arrow
    {



        border-top: 19px solid rgba(0, 0, 0, 0);
        border-left: 22px solid $asb-lime;
        border-bottom: 24px solid rgba(0, 0, 0, 0);
        position: absolute;
        right: -22px;
        top: 0;
        z-index: 1000;
         &::before{
                content: '';
                position: absolute;
          }
        &::after{
                content: '';
                width: 0;
                height: 0;
                border-top: 19px solid rgba(0, 0, 0, 0);
                border-left: 22px solid #000;
                border-bottom: 24px solid rgba(0, 0, 0, 0);
                position: absolute;
                top: -19px;
                left: -24px;
        }
    }
a{
  .arrow-right
    {

        position: absolute;
        margin: auto;
        right: -18px;
        top: 5px;
        width: 29px;
        height: 29px;
        transform: rotate(320deg);
        border-right: 2px solid $asb-lime;
        border-bottom: 2px solid $asb-lime;
        z-index: 1000;
      }
}



.form-control
{
  background: linear-gradient(to right,rgba(0,0,0,.5) 0,rgba(0,0,0,.8) 100%);
  border: 1px solid #848181;
  color: #fff;
  height: 30px;
  padding: 0 5px;

  option
  {
     background: #000; 
  }
}
.rzslider 
{
  .rz-pointer
  {
      background: $asb-lime;
  }
  .rz-bubble
  {
     color: #FFF;
     &.rz-limit
     {
      color: #FFF;
    }
  } 
}
.nav-tabs>.active>a
{
  background: #252525;
  border: 0px;
  color: #fff;
  border-radius: 0;
  border-bottom: 2px solid #b6ff00;
  text-transform: uppercase;
  padding: 12px 10px;


}
.btn
{
  border-radius: 0;
  padding: 4px 35px;
  position: relative;
  .icon{
    font-size: 20px;
    margin-left: 10px;
    bottom: 5px;
    position: absolute;
    right: 7px;

  }
}
.drop-box {
    background: #F8F8F8;
    border: 5px dashed #DDD;
    width: 200px;
    height: 65px;
    text-align: center;
    padding-top: 25px;
    margin: 10px;
}
.dragover {
    border: 5px dashed blue;
}
text-angular .btn{
  padding: 6px 12px;
}
.btn-dark
{
  color: #fff;
  background-color: #333333;
  border-color: rgba(147, 206, 10, 1) rgba(147, 206, 10, 1) rgba(147, 206, 10, 1);
  text-transform: uppercase;
  &:hover{
    background-color: darken(#333333,10%);
    color: #fff;
    text-decoration: none;
  }
  &.active{
    background-color: darken(#333333,10%);
    color: #fff;
    text-decoration: none;
    box-shadow: inset 0px 0px 15px -3px $asb-lime;

  }
}
.btn-inverse{
    @include button-variant(#FFF, #000000, $asb-lime);
    text-transform: uppercase;
    padding: 10px;
}
.btn-small
{
    padding: 10px 25px;
    min-width: 65px;
}
.btn-next
{
  margin-top: 50px;
  display: inline-block;
}
.btn-dark:focus
{
  color: white;
}
.panel-pixel
{
  background-color: #1A1A1A;
  border:1px solid $asb-lime;

    .panel-heading
    {
      background-color: #333333;
      text-transform: uppercase;
    }
    .panel-body.padded
    {
      padding-top:30px;
    }
    .table-striped>tbody>tr:nth-of-type(odd)
    {
      background-color: #333333;
    }

}
.input-padding
{
  margin-bottom: 30px;
}



/*Not Form CSS*/

.thumbnail-pixel
{
  background-color: rgba(0,0,0,0.5);
  border-color: $asb-lime;
  margin-bottom: 0px;
  margin: 5px;
  .btn
  {
      padding: 4px 0;
  }

}

.thumbnail-pixel.selectable
{
position: relative;
transition: all .3s;
    
  &:hover
  {
    box-shadow: 0 0 10px $asb-lime;
    cursor:pointer;
     .fly-button
    {
      display: block;
    }
  }
  .fly-button
  {
    position: absolute;
    z-index: 5;
    background: $asb-lime;
    padding: 10px;
    color: #000;
    border-radius: 50%;
    font-size: 23px;
    top: -4px;
    left: 0px;
    display: none;
    &.cancel
    {
      background: $asb-red;  
      color: #FFF;  
    }
  }
}

.thumbnail-pixel.lock{
    overflow: hidden;
  .carousel 
  {
    height: 80%;
    overflow: hidden;
  }

   @media (min-width: $screen-sm) {
      min-height: 160px;
    }

    @media (max-width: $screen-sm) {
      height: 160px;
    }
    margin-bottom: 10px;
    padding: 0;
     p{
      margin: 0;
    }
}

.label-primary {
    background-color: $asb-lime;
    color: #000;
}

.thumbnail-pixel.image{
  overflow: hidden;
    min-height: 150px;
    margin-bottom: 10px;
    padding: 0;
    p{
      margin: 0;
    }
}

.thumbnail-pixel.large-select
{     
    border: 1px solid #9dd70c;
      &:hover
      {
        box-shadow: inset 0 1px 20px 2px #9dd70c;
      }

     @media (min-width: $screen-lg) {
        min-height: 223px;
      }

      .img-responsive
      {
        width: 90%;
        display: block;
        margin: 10px auto;
      }
}

.small-thumbnail-pixel{
    min-height: 115px !important;
     @media (max-width: 768px) {
       min-height: 160px !important;
     }

     p{
      margin: 0;
    }
}


.add-lock 
{
  cursor: pointer;
  & > img
  {
    width: 50px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .holder 
  {
    width: 100px;
    margin: auto;
    margin-top: 5px;
  }
  &.image .holder
  {
      max-width: 105px;
  }
  
}
.add-lock:hover
{
      box-shadow: inset 0px 1px 20px 2px $asb-lime;
}

.nav-tabs.nav-justified>.active>a
{
  border: 1px solid rgba(221, 221, 221, 0);
}

.pixel-tabs
{

      
  @media (max-width: 768px) {
    li a
    {
      border-color: $asb-lime !important;
    }
  }

  li a
  {
    cursor: pointer;
    border-bottom-color: $asb-lime !important;
    border-top-color: $asb-lime !important;
    color:white;
    border-radius: 0px !important;
  }
  li:last-child a
  {
    border-right: 1px solid $asb-lime !important;
  }
  li:first-child a,   li.active:first-child a
  {
    border-left: 1px solid $asb-lime !important;
  }
  li.active>a
  {
    color:white;
    background-color: #1a1a1a;
    border-color: $asb-lime;
    border-left-color: #000;
  }
  li>a:hover
  {
    background-color: initial;
    border-color: rgba(222, 222, 222, 0);
  }
  
  li.active>a
  {


    background-color: #000;
      &:hover{
        background-color: #000;
      }
  }

}

.modal-content
{
  background-color: #333;
  border-color:$asb-lime;
  .modal-header{
    border-color:$asb-lime;
  }
  .modal-body
  {
    border-color:$asb-lime;
  }
  .modal-footer
  {
    border-color:$asb-lime;
  }
}

label.file-selection-block input[type="file"] {
    position: fixed;
    top: -1000px;
}

.file-selection-block {
  @extend .btn;
  @extend .btn-dark;
}

.pix-tab-container
{
   @media (max-width: 768px) {
      padding:10px;
      position: absolute;
      z-index: 10;
      background: rgb(10, 10, 10);
      margin: 0 auto;
      width: 90%;
      display:none;

      &.active
      {
        display:block;
      }
   }
}