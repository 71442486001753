.alert-pixel
{
    border: 1px solid #93ce0a;
    background: #000;
 	 position: relative;
}

.label-pixel {
    background-color: #93ce0a;
    color:#000;
}