/* navbar */

.navbar-toggle
{
    @media(min-width:$grid-float-breakpoint)
    {
        display: none !important;
    }
    .icon-bar{
        background-color: $asb-lime;
    }
    border: 1px solid $asb-lime;

}
.navbar-custom {
    padding: 5px 30px;
    position:fixed;
    top:0px;
    transition: all 0.3s;
    border-radius:0px;
    width: 100%;
    z-index: 500;

    .navbar-brand{
        padding:0;
        img{
            width: 228px;
                @media(min-width:$grid-float-breakpoint)
                {
                    width: 360px;
                }
        }
    }

     .nav .open>a, .nav .open>a:focus, .nav .open>a:hover,.nav>li>a:focus, .nav>li>a:hover
      {
        border-radius: 5px;
        text-decoration: none;
        background-color: $asb-lime;
        color: #000;
        .icon
            {
                color:#000;
            }
    }

    a{
        color: #FFF;
    }
    .icon
    {
        color: $asb-lime;
    }
}
.navbar-custom .offset_top {
    margin-top:20px;
}
.navbar-custom #menu_nav_logo {

    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
    transition: all 0.3s;

    width:100%;
    margin:auto;
}
.navbar-custom.smaller {
    padding:5px 10px;
    color:#000;
    background-color: rgba(16, 16, 16, 0.95);
    
    .navbar-brand{
        padding:0;
        img{
                width: 265px;
        }
    }

    @media(max-width:$screen-sm-min)
    {
        .navbar-brand{
            img{
                width: 228px;
            }
        }
    }
}
.navbar-custom.smaller .link_top_right > a{
    color:#222;
}
.navbar-custom.smaller .link_top_right > a:hover{
    color:#444;
}
.navbar-custom.smaller .offset_top {
    margin-top:8px;
}
.navbar-custom.smaller #menu_nav_logo {
    width:40%;
}
@media (max-width: $grid-float-breakpoint) {
    .navbar-custom, .navbar-custom.smaller{
        width:100%;
        padding:5px 10px;
        color:#000;
        background-color:#000;
        left:0px;
        right:0px;
    }
}

@media (min-width:$grid-float-breakpoint) {
    .navbar-toggle {
        display:block;
    }    
}
/*top links*/
.link_top_right {
    padding-right:10px;
    float:right;
    padding-top:4px;
}
.link_top_right > a{
    color:#ddd;
    line-height:12px;
    font-size:12px;
    text-decoration:none;
}
.link_top_right > a:hover{
    color:#eee;
    line-height:12px;
    font-size:12px;
    text-decoration:none;
}
.link_top_right > a:active, .link_top_right > a:visited {
    color:#ddd;
    line-height:12px;
    font-size:12px;
    text-decoration:none;
}

.main_menu {
    margin-left:20px;
    margin-top:10px;
}
.main_menu a, .main_menu a:visited  {
    color:#000;
    font-size:20px;

}
.main_menu a:hover {
    background-color:#333;
}

.navbar-toggle {
    float:left;
}
