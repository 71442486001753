

/* Main Page */

.main_page_sample {
    width:100%;
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);

}
.pad-vert {
    padding-bottom:6px;
}
.cblock {
    padding:7px;
    border:1px solid #93cb0b;
}
.c-img
{
    padding: 10px;
}
.cblock.block-gradient-black {
    background: rgba(0,0,0,0.5);
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
    background: -moz-linear-gradient(left, rgba(100,100,100,1) 0%, rgba(0,0,0,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(100,100,100,1) 0%,rgba(0,0,0,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(100,100,100,1) 0%,rgba(0,0,0,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00222222', endColorstr='#00000000',GradientType=1 ); /* IE6-9 */
}
.cblock.block-solid-white {
    background:#F2FFF2;
    color:#222;
}
.cblock.block-solid-grey {
    background:#4D4D4D;
}

.block-solid-spread-grey
{
    background: linear-gradient(to right,rgba(31, 30, 30, 0.5) 0,rgba(204,204,204,0) 15%,rgba(255,255,255,0) 85%,rgba(0, 0, 0, 0.5) 100%);    
    background-color: #656565;
}



.cblock.block-split-bottom-right {
    background: linear-gradient(to bottom right,#666 50%,#919191 51%);
}

.cblock.height-150 {
    min-height:150px;
}

.mainpage_block_wrapper {
    display:block;
    height:100%;
    position:relative;
}
.padded_in p {
    padding:5px 30px;
}


.btn.btn-custom {
    background-color:transparent;
    border-color:transparent;
}
.btn.btn-custom:hover {
    background-color:rgba(200,200,200, 0.5);
    border-color:rgba(200,200,200, 0.2);
}
button.close
{
    color: $asb-lime;
    border: 2px solid;
    border-radius: 5px;
    padding: 0px 4px;
    font-size: 16px;
    font-weight: 200;
    opacity: 1;
    position: absolute;
    top: 5px;
    right: 4px;
    &:hover{
        opacity: 1;
        color:lighten($asb-lime,10%) !important;
    }
}

span.heading-icon {
    font-size: 14px;
    position: relative;
    top: -5px;
}

#float-help-section
{
    h2{
        display: block;
        border-bottom: 1px solid #93ce0a;
        margin-top: 0px;
    }
    &.active{
        right: 0px;
        .global_help_text {
            box-shadow: 1px 2px 6px $asb-lime;
        }
    }
    transition:all 0.5s;
    z-index: 10;
    position: fixed;
    top: 30%;
    right: -360px;
    width: 360px;
    max-width: 100%;
    height: 350px;
    .float-container
    {
        height: 100%;
        position: relative;
    }
    .global_help_text {
        background-color: rgba(0,0,0,.95);
        background: #1f1f1f;
        padding: 20px;
        transition: all .3s;
        z-index: 2;
        height: 100%;
        height: 100%;
        margin-right: 10px;
        border: 1px solid #9dd70c;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
    }
    .global_help_text.active {
        left:0px;
    }
    #float-help-buttons{
        z-index: 2;
        top: 90%;
        position: absolute;
        left: -15px;
        transform: rotate(-90deg);
        transform-origin: 0;
    }
    
    .video-block
    {
        border: 1px #93ce0a solid;
        padding: 0px;
        margin: 2px;
        border-radius: 5px;
        cursor: pointer;
        &:hover{

        }
    }

}
#pixel-definition
{
    h2{
        font-size: 28px;
        margin-top: 0; 
    }
}

#testimonials-slider
{
    min-height: 95px;
    padding: 14px 55px;
    .carousel-indicators li{display:none;}
}


span.emphasize {
    color: $asb-lime;
}
/* .modal-fullscreen */

.modal-fullscreen {
  background: transparent;
}
.modal-fullscreen .modal-content {
  background: transparent;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-backdrop.modal-backdrop-fullscreen {
  background: #ffffff;
}
.modal-backdrop.modal-backdrop-fullscreen.in {
  opacity: .2;
  filter: alpha(opacity=2);
}

.modal-backdrop {
    background-color:#fff;
}
.modal-backdrop.in {
    opacity:0.95;
}

/* .modal-fullscreen size: we use Bootstrap media query breakpoints */

.modal-fullscreen .modal-dialog {
  margin: 0;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.action-block
{
    border: 2px solid #93cb0b;
    background: #73943c;
    display: block;
    border-radius: 10px;
    color: #000;
    box-shadow: inset 0 -1px 26px 3px #101c04;
    margin-top: 10px;
    padding: 5px;
    transition: 0.5s all;
    min-height: 75px;
    font-weight: bold;

    img
    {
     margin:auto;
    }

    @media (max-width : $screen-md)
    {
        text-align:center;
    }

    &:hover
    {
        color: #000;
        text-decoration:none;
        box-shadow: 0px 2px 19px 3px #93cb0b;
    }

    span.emphasize{
        color:#FFF;
    }

}
    

@media (min-width: 768px) {
  .modal-fullscreen .modal-dialog {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .modal-fullscreen .modal-dialog {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .modal-fullscreen .modal-dialog {
     width: 1170px;
  }
}

#pixelock_footer {
    @extend .clearfix;
    margin-top: 10px;
    background: rgba(42,39,39,.86);
    padding: 6px;
    border: 1px solid #96c300;
    margin: auto;
    border-radius: 5px;
    width: 600px;
    right: 13px;
    position: relative;
    text-align: center;
}

#footer {
    min-height:40px;
    padding:40px 20px;
    margin-top:20px;

    text-align: center;

    color:#999;
    font-size:14px;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

html {
    min-height:100%;
}
body {
    
    background: url('../images/Pixel_BG.png') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  /*
    background-image:url('../images/Pixel_BG.png');
    background-size: 100%;
    background-repeat: no-repeat;
*/

    font-family : Helvetica;
    color : #FFFFFF;
    color : rgb(255, 255, 255);
}

body, #pixelock_global_wrapper {
    min-height:100%;

    position: relative;
    z-index:2;
}

#pixelock_global_wrapper {
  padding-top: 96px;;
}
#pixelock_background
{
    background: rgba(0,0,0,0.5);
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
    background: -moz-linear-gradient(left, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#55000000', endColorstr='#a8000000',GradientType=1 ); /* IE6-9 */

    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index:1;
    pointer-events:none;
}

/* Overlay */

#pixelock_overlay
{
    // display: none;
    // position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index:10001;
    -moz-opacity: 0.8;
    opacity: .80;
    filter: alpha(opacity=80);
}

#pixelock_content
{
    // display: none;
    // position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10002;
    overflow: auto;
    /* prevent selection of text elements */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#pixelock_container
{
    margin:auto;
    width: 620px;
    height:80%;
    text-align:right;

    font-family: Helvetica;
    font-size: 11px;
}

#pixelock_social
{
    margin:auto;
    width:300px;
    height:25px;
    margin-top:5px;
    text-align: center;
}

#pixelock_header
{
    display: none;
    margin:auto;
    height:70px;
    width: 620px;
    margin-bottom: -20px;
}

.pixelock_header_bar_icon
{
    float:right;

    -moz-opacity: 0.5;
    opacity: .50;
    filter: alpha(opacity=50);
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
}

.pixelock_header_bar_icon:hover
{
    -moz-opacity: 1.0;
    opacity: 1.0;
    filter: alpha(opacity=100);
    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
}

#pixelock_header_settings
{
    color: white;
    height:16px;
    margin-top:20px;
    float:right;
    margin-right:-10px;

    font-family: Helvetica;
    font-size: 11px;
}

#pixelock_lightbox
{
    width: 594px;
    height: 455px;
    padding: 0px;
    border: 2px solid #96c300;
    background-color: #242424;
    z-index: 10003;
    overflow: auto;
    color: white;
    text-align:left;
    overflow:hidden;
    position: relative;
}

#pixelock_close_button
{
    display:none;
    float:right;
    margin-right:-10px;
    margin-bottom:-10px;
    height:16px;
    width:16px;
    z-index: 10010;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAACXBIWXMAAAsTAAALEwEAmpwYAAADGGlDQ1BQaG90b3Nob3AgSUNDIHByb2ZpbGUAAHjaY2BgnuDo4uTKJMDAUFBUUuQe5BgZERmlwH6egY2BmYGBgYGBITG5uMAxIMCHgYGBIS8/L5UBFTAyMHy7xsDIwMDAcFnX0cXJlYE0wJpcUFTCwMBwgIGBwSgltTiZgYHhCwMDQ3p5SUEJAwNjDAMDg0hSdkEJAwNjAQMDg0h2SJAzAwNjCwMDE09JakUJAwMDg3N+QWVRZnpGiYKhpaWlgmNKflKqQnBlcUlqbrGCZ15yflFBflFiSWoKAwMD1A4GBgYGXpf8EgX3xMw8BSMDVQYqg4jIKAUICxE+CDEESC4tKoMHJQODAIMCgwGDA0MAQyJDPcMChqMMbxjFGV0YSxlXMN5jEmMKYprAdIFZmDmSeSHzGxZLlg6WW6x6rK2s99gs2aaxfWMPZ9/NocTRxfGFM5HzApcj1xZuTe4FPFI8U3mFeCfxCfNN45fhXyygI7BD0FXwilCq0A/hXhEVkb2i4aJfxCaJG4lfkaiQlJM8JpUvLS19QqZMVl32llyfvIv8H4WtioVKekpvldeqFKiaqP5UO6jepRGqqaT5QeuA9iSdVF0rPUG9V/pHDBYY1hrFGNuayJsym740u2C+02KJ5QSrOutcmzjbQDtXe2sHY0cdJzVnJRcFV3k3BXdlD3VPXS8Tbxsfd99gvwT//ID6wIlBS4N3hVwMfRnOFCEXaRUVEV0RMzN2T9yDBLZE3aSw5IaUNak30zkyLDIzs+ZmX8xlz7PPryjYVPiuWLskq3RV2ZsK/cqSql01jLVedVPrHzbqNdU0n22VaytsP9op3VXUfbpXta+x/+5Em0mzJ/+dGj/t8AyNmf2zvs9JmHt6vvmCpYtEFrcu+bYsc/m9lSGrTq9xWbtvveWGbZtMNm/ZarJt+w6rnft3u+45uy9s/4ODOYd+Hmk/Jn58xUnrU+fOJJ/9dX7SRe1LR68kXv13fc5Nm1t379TfU75/4mHeY7En+59lvhB5efB1/lv5dxc+NH0y/fzq64Lv4T8Ffp360/rP8f9/AA0ADzT6lvFdAAAAIGNIUk0AAHolAACAgwAA+f8AAIDpAAB1MAAA6mAAADqYAAAXb5JfxUYAAAEtSURBVHjahNG9ihRhEIXh5+v5emiDWdj7EAw6W9BEMDAQMe4VTESMWr0ABQ0EDfoe7CsQI1PBaEb8AcFIxM1dUMd1Z+0y6HbXSKuSgnrrUFUnhX9H5qykkJpoSSCkbuhDeCFPYBPt5Xo9NlWetugnBTRDe6XeO5ZNLtbPJqQYpy/VHzz2fcpHPjpfRxsNws7yYezGGFePq924EzvLkBl8cuCC59bu++atmeuyLwYUhI2NU85Z2rOW3DATfhrGJX85cuhAlpUqc6Xx4Elh8MOR7IG5ytzcE6WsOAGy0l2lyi03lcoJ+QN071YL91RuW1i4hkbp8yo6UjhjaKI9Xe/bQuCrLe9XqSv6NwqIftO9Wm2bmcmyba9Xm+7vV4v+0Mv2xMPUlf3oc/qf3b8HALj6fYcK51O7AAAAAElFTkSuQmCC');

    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
}

#pixelock_close_button:hover
{
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAe9JREFUeNqkUzFLI0EU/nazWaI5LSQEwfOaCCktoo1VSGsrHJy5PmUKwcL2rrIwrSDHgYuNqfwBoiAi6iI2lwhJiF4ODCcJxCSXZLPZe2+SDbtLrBz4dnZn3vd9b96+kSzLwnuGwo9DSYKPZoYMJGlKvxGfGQCaSS+Mr2SueAKSFJD+vL0da7fbro1AIIDj3V1bWLPXJT7CKAPh/IXIlUplon04HEZ2b0/nTCgDjTOQbWeTnbe2Yvl8Hh81Dc1m0wVeK5VKWE+lYubwiEnB5Ax+ALeddNq6TiQs5+DvSWu/Nzct5lh2DfqEcrmMVquF7NISNgoFdDodfDo6AteiQN+yLON0ZQV+vx9/q1XBwbDoQ4Fer4d+v4+ZYBA/QyHkcjlUKZCFJKrROZFVLpph4F+jMRYYZ8Dk3usr2rUagoMBfD6fcFNVVeCD89+TiCsDg9AlovXyginTRPTszEVWFAXxhwdMUxxDJTPDe4QgEaepKIsO8v3qKm6Wl4UAY61YFAIBytBbg8zJ3Z0empvDYzwuyL+IvEB7jKtoVARfRCJC4LJW05kzbqTvVCTD7sL5+dhTtyuIzvFnJJat13VyzfipkXYcjQTu8QZt7D8/6xFO0wNeOyByY3QfXJfJI4Jv9frEy8TOsw7y+AjvGf8FGAAk/gDhOKgTMwAAAABJRU5ErkJggg==');

    transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
}
#pixelock_loader{
    width: 100%;
    height: 100%;
     img{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 65px;
     }
}

#pixelock_logo
{
    float:left;
    padding-top:15px;
    margin-bottom:-15px;
    z-index: 10010;
}

.pixelock_image
{
    margin:0px;
    padding:0px;
}

.pixelock_image_grid
{
    width:601px;
    height:450px;
}

.pixelock_error_grid
{
    width:601px;
    height:450px;
}

.pixelock_error_grid > p
{
    text-align:center;
}

.pixelock_table_row
{
    width:601px;
    height:50px;
}
.pixelock_table_row_bottom
{
    width:601px;
    height:48px;
}

.pixelock_table_cell
{
    border-top:1px solid $asb-clear;
    border-left:1px solid $asb-clear;
    width:49px;
    height:50px;
    float:left;
    &:hover{
        box-shadow: 0 0 10px $asb-lime;
    }
}

.pixelock_settings_table_cell
{
    border-top:1px solid black;
    border-left:1px solid black;
    /*border-right:1px solid black;*/
    width:149px;
    height:49px;
    float:left;
    background-color: #404040;
}
.pixelock_settings_right_box
{
    border:1px solid black;
    width:449px;
    height:450px;
    float:right;
}

.pixelock_table_settings_column
{
    width:150px;
    height:450px;
    float:left;
}

.pixelock_settings_table_link
{

    display:block;
    width:100%;
    height:100%;
    background-color:#404040;
    @media (min-width: $screen-md) {
     opacity: .50;
    }
     opacity: .3;
    transition: all 0.2s ease-in-out;
}

.pixelock_settings_table_link:hover
{
    @media (min-width: $screen-md) {
        opacity: .20;
    }
    background-color:#808080;
    transition: all 0.2s ease-in-out;

}

.pixelock_settings_pressed
{
    background-color:#808080;
    transition: all 0.2s ease-in-out;

}

.pixelock_table_link
{
    display:block;
    width:100%;
    height:100%;
    background-color:black;
    
    @media (min-width: $screen-md) {
     opacity: .50;
    }
     opacity: .3;
    transition: all 0.2s ease-in-out;
}

.pixelock_table_link:hover
{
    -moz-opacity: 0.2;
    opacity: .20;
    filter: alpha(opacity=20);
    background-color:white;
    transition: all 0.2s ease-in-out;
}

.pixelock_table_cell_right
{
   // border-right:1px solid black;
}

.pixelock_table_cell_bottom
{
    //border-bottom:1px solid black;
}

#pixelock_loader
{
    margin:auto;
    padding-top:35%;
    text-align:center;
}

.pixelock_rounded_corners
{
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    border-radius: 5px;
    /*behavior: url(http://localhost/pixelock-bookmarklet/img/border-radius.htc);*/
    /*behavior: url(http://new.pixelock.com/img/border-radius.htc);*/
}

div#preload
{
    display: none;
}


#pixelock_debug
{
    color: white;
    height:0px;
    overflow:visible;

    font-family: Helvetica;
    font-size: 9px;
}

#pixelock_settings_header
{
    color:white;
    font-family: Helvetica;
    font-size: 13px;
    margin-left:30px;

}

.no-pad {
    padding:0px 0px 0px 0px;
}

div#pixelock_status_block {
    width: 621px;
    height: 50px;
    background: rgba(6, 6, 6, 0.68);
    margin-top: 10px;
    border-radius: 3px;
    border-radius: 3px;
    border: 2px solid #96c300;
}

#pixelock_done_overlay {
    width: 590px;
    height: 455px;
    position: absolute;
    top: 0;
    margin: auto;
    background: rgba(159, 159, 159, 0.65);
}

.demo-container 
{
    position: relative;
    padding-bottom: 80%;
    height: 0;
    overflow: hidden;
    #demo-area
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &>div
        {
            width: 540px;
            height: 435px;
            max-width: 100%;
        }
    }  
}

// status block code
#image_status_block
{
    float: left;
    width:50%;
    @extend .clearfix;
    li.image{
        float: left;
        width: 20%;
        padding: 5px;
        display: block;
        img{
            width:100%;
        }
    }
}
#repeat-button{
    float: right;
}

.mono-font
{
    font-family: 'Open Sans', sans-serif;
}
.lime-link
{
    color:$asb-lime;
    &:hover,
    &:focus
    {
        color:darken($asb-lime,10%);
    }
}

.pagination>li>a:focus, 
.pagination>li>a:hover, 
.pagination>li>span:focus, 
.pagination>li>span:hover 
{
    background-color: lighten($asb-lime,10%);
    color: $asb-matt;
}

#lockCanvas,
#lockShow,
#confirmLockCanvas
{
    overflow-x: auto;
    max-width: 100%;
}

.critical-alerts
{
    padding: 50px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 5;
    top: 0;
    margin: auto;
    background: rgba(27, 27, 27, 0.69);
    bottom: 0;
    .alert
    {
        width:50%;
        margin: auto;
        width: 50%;
        position: relative;
        top: 50px;
    }
}

.active-alert
{
    display: none;
}

.no-support-body
{
    .active-alert
    {
        display: none;
    }
}

.no-support-body
{
    .active-alert
    {
        display: block;
    }
}

.no-support-body
{
    position:fixed;
    height:100%;
    
    .active-alert
    {
        display: block;
    }
}