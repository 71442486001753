@font-face {
    font-family: 'icomoon';
    src:    url(' ../fonts/icomoon.eot?jpr4zd');
    src:    url(' ../fonts/icomoon.eot?jpr4zd#iefix') format('embedded-opentype'),
        url(' ../fonts/icomoon.ttf?jpr4zd') format('truetype'),
        url(' ../fonts/icomoon.woff?jpr4zd') format('woff'),
        url(' ../fonts/icomoon.svg?jpr4zd#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-lifebuoy:before {
    content: "\e90c";
}
.icon-cancel:before {
    content: "\e932";
}
.icon-avatar:before {
    content: "\e900";
}
.icon-avatar-1:before {
    content: "\e901";
}
.icon-back:before {
    content: "\e902";
}
.icon-book:before {
    content: "\e903";
}
.icon-plus:before {
    content: "\e904";
}
.icon-chat:before {
    content: "\e905";
}
.icon-chat-1:before {
    content: "\e906";
}
.icon-chat-2:before {
    content: "\e907";
}
.icon-copy:before {
    content: "\e908";
}
.icon-dislike:before {
    content: "\e909";
}
.icon-download:before {
    content: "\e90a";
}
.icon-download-1:before {
    content: "\e90b";
}
.icon-edit:before {
    content: "\1f435";
}
.icon-envelope:before {
    content: "\e90d";
}
.icon-folder:before {
    content: "\e90e";
}
.icon-garbage:before {
    content: "\e90f";
}
.icon-glasses:before {
    content: "\e910";
}
.icon-hand:before {
    content: "\e911";
}
.icon-headphones:before {
    content: "\e912";
}
.icon-heart:before {
    content: "\e913";
}
.icon-house:before {
    content: "\e914";
}
.icon-like:before {
    content: "\e915";
}
.icon-link:before {
    content: "\e916";
}
.icon-logout:before {
    content: "\e917";
}
.icon-magnifying-glass:before {
    content: "\e918";
}
.icon-monitor:before {
    content: "\e919";
}
.icon-musical-note:before {
    content: "\e91a";
}
.icon-next:before {
    content: "\e91b";
}
.icon-next-1:before {
    content: "\e91c";
}
.icon-padlock:before {
    content: "\e91d";
}
.icon-paper-plane:before {
    content: "\e91e";
}
.icon-phone-call:before {
    content: "\e91f";
}
.icon-photo-camera:before {
    content: "\e920";
}
.icon-pie-chart:before {
    content: "\e921";
}
.icon-piggy-bank:before {
    content: "\e922";
}
.icon-placeholder:before {
    content: "\e923";
}
.icon-printer:before {
    content: "\e924";
}
.icon-reload:before {
    content: "\e925";
}
.icon-settings:before {
    content: "\e926";
}
.icon-settings-1:before {
    content: "\e927";
}
.icon-share:before {
    content: "\e928";
}
.icon-shopping-bag:before {
    content: "\e929";
}
.icon-shopping-cart:before {
    content: "\e92a";
}
.icon-shuffle:before {
    content: "\e92b";
}
.icon-speaker:before {
    content: "\e92c";
}
.icon-star:before {
    content: "\e92d";
}
.icon-tag:before {
    content: "\e92e";
}
.icon-upload:before {
    content: "\e92f";
}
.icon-upload-1:before {
    content: "\e930";
}
.icon-vector:before {
    content: "\e931";
}
